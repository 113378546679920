import React, { useState } from "react";

import { Helmet } from "react-helmet";

import MenuStrip from "../components/menu_strip";
import PersonalFinance from "../components/personal_finance";
import storageService from "../../services/storage";

import Investmentcontainer from "../components/investment_container";

import { useQuery } from "urql";

import Header from "../components/header";
import "./wallet.css";

const walletsummary = `
        query getMyWalletSummary {
          getMyWalletSummary
          myInvestmentStatistics
        }
      `;

const Wallet = (props) => {
  let tmp = storageService.getStorageData("num_vis");
  const [visibility, setVisibility] = useState(tmp);

  if (visibility == null) {
    storageService.setStorageData("num_vis", "TRUE");
    setVisibility("TRUE");
  }

  const [result, reexecuteQuery] = useQuery({
    query: walletsummary,
    requestPolicy: "network",
  });

  const { data, fetching, error } = result;

  let _F = [];
  let _G = [];

  if (data) {
    let groupmeber = false;
    if (data.myInvestmentStatistics.subscriptionValidity) {
      try {
        let tmp_inv = data.myInvestmentStatistics.balance;
        groupmeber = true;
      } catch {}
    }

    _F.push(<PersonalFinance data={data.getMyWalletSummary} key={1} visibility={visibility}></PersonalFinance>);
    if (groupmeber == true) {
      _G.push(
        <Investmentcontainer
          containerType='EDITOR'
          entity_uid={null}
          data={data.myInvestmentStatistics}
          setFee={null}
          loans={[]}
          fee={null}></Investmentcontainer>
      );
    }
  }

  return (
    <div className='wallet-container'>
      <Helmet>
        <title>Vanuatu post - Wallet</title>
        <meta property='og:title' content='Vanuatu post - Wallet' />
      </Helmet>
      <Header rootClassName='header-root-class-name'></Header>
      <div className='wallet-contentframe'>
        <div className='wallet-contentcontainer'>
          <MenuStrip rootClassName='menu-strip-root-class-name6' setVisibility={setVisibility} visibility={visibility}></MenuStrip>

          <div className='wallet-homepanel'>
            <div className='wallet-container1'>
              <div className='wallet-headerfinance'>
                <div className='wallet-container2'>
                  <svg viewBox='0 0 1024 1024' className='wallet-icon'>
                    <path d='M480 64c-265.096 0-480 214.904-480 480 0 265.098 214.904 480 480 480 265.098 0 480-214.902 480-480 0-265.096-214.902-480-480-480zM480 928c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.078 0 384 171.922 384 384s-171.922 384-384 384zM512 512v-128h128v-64h-128v-64h-64v64h-128v256h128v128h-128v64h128v64h64v-64h128.002l-0.002-256h-128zM448 512h-64v-128h64v128zM576.002 704h-64.002v-128h64.002v128z'></path>
                  </svg>
                  <span className='wallet-text'>Finance</span>
                  <div className='wallet-container3'></div>
                </div>
              </div>
              {_G}
              {_F}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
