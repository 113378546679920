import React, { useState } from "react";
import UserLoanitem from "./user_loan_item";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { useWindowSize } from "react-use";

import "./investment_container.css";

const numberWithCommas = (x) => {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch {
    return 0;
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

const Investmentcontainer = (props) => {
  let bal = [[100000, 0]];
  bal = props.data.tsBalance;

  let bali = [[100000, 0]];
  bali = props.data.inBalance;

  try {
    bal.push({ timestamp: props.data.ts, value: props.data.balance });
  } catch {}

  try {
    bali.push({ timestamp: props.data.ts, value: props.data.investedBalance });
  } catch {}

  try {
    bal = bal.map(numerizeFloat);
  } catch {
    bal = [[100000, 0]];
    bal = bal.map(numerizeFloat);
  }

  try {
    bali = bali.map(numerizeFloat);
  } catch {
    bali = [[100000, 0]];
    bali = bali.map(numerizeFloat);
  }

  let tmp = [];
  if (bal.length == 0) {
    tmp = [[1000, 0.1]];
  } else {
    tmp = bal;
  }

  let tmpi = [];
  if (bali.length == 0) {
    tmpi = [[1000, 0.1]];
  } else {
    tmpi = bali;
  }

  let apex_data = [
    { name: "Available", data: tmp },
    { name: "Invested", data: tmpi },
  ];

  let apex_i_data = [{ data: tmpi }];

  let { width, height } = useWindowSize();
  if (width > 565) {
    width = 565;
  } else {
    width = width - 55;
  }

  let apex_options = {
    colors: ["#91b673ff", "#c2a764ff"],
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return ~~val / 1000 + "k";
        },
      },
    },
    xaxis: {
      type: "datetime",
      tickAmount: 8,
      labels: {
        show: false,
      },
    },
    chart: {
      id: "area-datetime",
      type: "area",
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        shared: true,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    tooltip: {
      shared: true,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.0,
        stops: [0, 100],
        gradientToColors: ["#91b673ff", "#c2a764ff"],
      },
    },
  };

  var _d = new Date(props.data.subscriptionValidity);
  let _F = [];
  let counter = 0;
  try {
    props.loans.map((loan) => {
      if (counter == 0) {
        _F.push(<UserLoanitem data={loan} key={loan.id}></UserLoanitem>);
        counter = 1;
      }
    });
  } catch {}

  const chenge_fee = () => {
    if (props.fee == "REQSUB") {
      props.setFee("NONE");
    } else {
      props.setFee("REQSUB");
    }
  };

  return (
    <div className='investmentcontainer-investmentcontainer'>
      <Link to={"/ledger/" + props.data.madruhamAccount} style={{ width: "100%" }}>
        <div className='investmentcontainer-container'>
          <ErrorBoundary>
            <Chart options={apex_options} series={apex_data} type='area' height='100%' width={width} />
          </ErrorBoundary>
        </div>
        <div className='investmentcontainer-container1'>
          <div className='investmentcontainer-container2'>
            <span className='investmentcontainer-text'>Available Balance:</span>
            <span className='investmentcontainer-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
          </div>
          <div className='investmentcontainer-container3'>
            <span className='investmentcontainer-text2'>Invested Balance:</span>
            <span className='investmentcontainer-text3'>{numberWithCommas(props.data.investedBalance) + " VT"}</span>
          </div>
          <div className='investmentcontainer-container4'>
            <span className='investmentcontainer-text4'>Total</span>
            <span className='investmentcontainer-text5'>{numberWithCommas(props.data.investedBalance + props.data.balance) + " VT"}</span>
          </div>
        </div>
        {props.data.isValid == true ? (
          <span className='investmentcontainer-text6'>{"Valid until: " + _d.toISOString().split("T")[0]}</span>
        ) : (
          <span className='investmentcontainer-text7'>{"Invalid since: " + _d.toISOString().split("T")[0]}</span>
        )}
      </Link>

      {(props.containerType == "MAIN") & (props.data.loans == true) ? (
        <>
          <span className='entityeditor-text2'>{"Active Loan"}</span>
          <Link
            to={
              "/cbm_user_loan/" +
              props.loans[0].id +
              "/" +
              props.entity_uid +
              "/" +
              props.loans[0].debtAccount[0].accountNo +
              "/" +
              props.loans[0].createdBy
            }
            style={{ width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
            {_F}
          </Link>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Investmentcontainer;
