import React from "react";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import Nohelpitemyet from "../components/nohelpitemyet";
import Threaditem from "../components/threaditem";
import MenuStrip from "../components/menu_strip";
import Threadusermessage from "../components/threadusermessage";

import "./threads.css";

const Threads = (props) => {
  return (
    <div className='threads-container'>
      <Helmet>
        <title>Vanuatu post - Wallet</title>
        <meta property='og:title' content='Vanuatu post - Wallet' />
      </Helmet>
      <Header rootClassName='header-root-class-name27'></Header>
      <div className='threads-contentframe'>
        <div className='threads-contentcontainer'>
          <MenuStrip rootClassName='menu-strip-root-class-name15'></MenuStrip>
          <div className='threads-homepanel'>
            <div className='threads-pochat'>
              <Nohelpitemyet></Nohelpitemyet>
            </div>
            <div className='threads-container1'>
              <input type='text' placeholder='search' className='threads-textinput input' />
              <svg viewBox='0 0 1024 1024' className='threads-icon'>
                <path d='M554 554q80 0 168 35t88 93v86h-512v-86q0-58 88-93t168-35zM838 562q74 12 130 43t56 77v86h-128v-86q0-68-58-120zM554 470q-52 0-90-38t-38-90 38-90 90-38 90 38 38 90-38 90-90 38zM768 470q-20 0-38-6 38-54 38-122t-38-122q18-6 38-6 52 0 90 38t38 90-38 90-90 38zM342 426v86h-128v128h-86v-128h-128v-86h128v-128h86v128h128z'></path>
              </svg>
            </div>
            <div className='threads-searchcontainer'>
              <Threadusermessage></Threadusermessage>
            </div>
            <div className='threads-messagelist'>
              <Threaditem></Threaditem>
              <Threaditem></Threaditem>
              <Threaditem></Threaditem>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Threads;
