import React, { useState, useRef } from "react";
import { useMutation } from "urql";
import Avatar from "react-avatar";

import { Helmet } from "react-helmet";

import Header from "../components/header";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Fab from "@mui/material/Fab";
import { useHistory, Link } from "react-router-dom";
import { Camera } from "react-camera-pro";
import uuid from "react-uuid";
import storageService from "../../services/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TransferForm from "../components/transferForm";

import { dataURItoBlob } from "../../services/utilities";

import { srcToWebP, blobToWebP, arrayBufferToWebP } from "webp-converter-browser";

import "./transfer.css";

let SUBMIT_TRANSFER = `
        mutation submitTransferPersonal($to_entity:ID!, $relation:String!, $amount:Int!, $imageId:ID!, $transactionId:String!) {
          submitTransferPersonal(toEntity:$to_entity, relation:$relation, amount: $amount, imageId: $imageId, transactionId: $transactionId)
        }
      `;

let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const zeroPad = (num, places) => String(num).padStart(places, "0");

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Transfer = (props) => {
  let history = useHistory();
  const trans_uid = uuid();

  let SAVE_PROFILE = `
        mutation saveAdminFile($uid:ID!, $mime_type:String!, $path:String!) {
          saveAdminFile(data:[{id:$uid, mimeType:$mime_type, path:$path, digitalIdBacklink:{id:"<did_uid>"}}]) {
            id
          }
        }
      `;

  const [fabon, setfabon] = useState(true);
  const [cameradirection, setcameradirection] = useState("user");

  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [showprogress, setShowProgress] = useState(false);

  const [result_prof, save_profile] = useMutation(SAVE_PROFILE);
  const [result_dep, submit_deposit] = useMutation(SUBMIT_TRANSFER);

  const [selectedEntity, setSelectedEntity] = useState("");
  const [thisdata, setthisdata] = useState(null);
  const [relation, setRelation] = useState("NONE");

  const [stage, setStage] = useState("FIRST");

  const [thisImage, setThisImage] = useState("");

  const [amount_to_transfer, set_amount_to_transfer] = useState(null);

  const submitTransfer = (event) => {
    event.preventDefault();
    submit_deposit({
      to_entity: selectedEntity,
      relation: relation,
      amount: Number(amount_to_transfer),
      imageId: thisImage,
      transactionId: trans_uid,
    }).then((result_dep) => {
      if (result_dep.data !== null) {
        setStage("SUCCESS");
      } else {
        setStage("ERROR");
      }
    });
  };

  return (
    <div className='depositmanager-container'>
      <Helmet>
        <title>Vanuatu post - Transfer</title>
        <meta property='og:title' content='Vanuatu post - Transfer' />
      </Helmet>
      <Header rootClassName='header-root-class-name30'></Header>
      <div className='depositmanager-contentframe'>
        <div className='depositmanager-contentcontainer'>
          <div className='depositmanager-homepanel'>
            {stage == "FIRST" && (
              <TransferForm
                amount={amount_to_transfer}
                setAmount={set_amount_to_transfer}
                stage={stage}
                setStage={setStage}
                thisdata={thisdata}
                setthisdata={setthisdata}
                selectedEntity={selectedEntity}
                setSelectedEntity={setSelectedEntity}
                relation={relation}
                setRelation={setRelation}></TransferForm>
            )}

            {stage == "SECOND" && (
              <div className='depositmanager-takepicturebox'>
                <form className='kycedititem-page05' id='pp_form'>
                  <span className='kycedititem-text18'>
                    Take a profile picture of the yourself, make sure your face is centered and fully visible
                  </span>
                  {image ? (
                    <div>
                      <img src={image} className='uploadid-uploadid' style={{ width: "100%", height: "auto" }} />
                      {showprogress && (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress />
                        </Box>
                      )}
                    </div>
                  ) : (
                    <Camera ref={camera} facingMode={cameradirection} aspectRatio={1 / 1} className='uploadid-uploadid' />
                  )}

                  {image ? (
                    <div className='kycedititem-container25'>
                      <button
                        type='button'
                        className='kycedititem-button15 button'
                        onClick={() => {
                          if (showprogress == false) {
                            setImage(null);
                          }
                        }}>
                        New Picture
                      </button>

                      <button
                        type='button'
                        className='kycedititem-button16 button'
                        onClick={async () => {
                          if (showprogress == false) {
                            var cf = dataURItoBlob(image);

                            setShowProgress(true);

                            const webpBlob = await blobToWebP(cf);

                            const options = {
                              method: "PUT",
                              headers: {
                                AccessKey: process.env.REACT_APP_STORAGE_ACCESSKEY,
                                "content-type": "application/octet-stream",
                              },
                              body: webpBlob,
                            };

                            const img_uid = uuid();

                            const pathprepare = "/" + db_name + "/transfer/" + trans_uid + "/" + img_uid;

                            const uriimg = process.env.REACT_APP_STORAGE_BASE_HOST + pathprepare + ".webp";

                            setThisImage(img_uid);

                            fetch(uriimg, options)
                              .then((response) => response.json())
                              .then((response) => {
                                save_profile({ uid: img_uid, mime_type: "image/webp", path: pathprepare + ".webp" }).then((result) => {
                                  setShowProgress(false);

                                  try {
                                    setStage("THIRD");
                                  } catch {
                                    toast.error("Some error happened..");
                                  }
                                });
                              })
                              .catch((err) => {
                                console.error(err);
                                toast.error("Upload error..");
                              });
                          }
                        }}>
                        Submit Picture
                      </button>
                    </div>
                  ) : (
                    <>
                      {fabon == true && (
                        <Fab
                          color='primary'
                          aria-label='camera'
                          size='large'
                          style={{ position: "fixed", bottom: "50px" }}
                          onClick={() => {
                            let current_photo = camera.current.takePhoto();
                            setImage(current_photo);
                          }}>
                          <svg viewBox='0 0 1024 1024' className='floatinccameraicon'>
                            <path d='M1024 810.667v-469.333c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-147.84l-72.661-109.013c-7.765-11.52-20.736-18.987-35.499-18.987h-256c-13.909 0.085-27.307 6.741-35.499 18.987l-72.661 109.013h-147.84c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v469.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h768c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496zM938.667 810.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-768c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-469.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c14.763 0 27.733-7.467 35.499-18.987l72.661-109.013h210.347l72.661 109.013c8.192 12.245 21.589 18.901 35.499 18.987h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165zM725.333 554.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 554.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z'></path>
                          </svg>
                        </Fab>
                      )}
                    </>
                  )}
                </form>
              </div>
            )}

            {stage == "THIRD" && (
              <form className='transfermanager-transfer-form' onSubmit={submitTransfer}>
                <span className='transfermanager-text01'>Confirm your TRANSFER to</span>

                <div className='transfermanager-selecteduser'>
                  <div className='transfermanager-useritem-selected'>
                    <Avatar
                      src={process.env.REACT_APP_STORAGE_READ_HOST + "/" + thisdata.avatar.path}
                      size='50'
                      name={thisdata.name}
                      className='useritem-add-image'
                    />

                    <div className='transfermanager-container2'>
                      <span className='transfermanager-text02'>{thisdata.name}</span>
                    </div>
                  </div>
                </div>

                <span className='transfermanager-text03'>{numberWithCommas(amount_to_transfer) + " VT"}</span>
                <button type='submit' className='transfermanager-button button'>
                  TRANSFER NOW
                </button>
              </form>
            )}

            {stage == "SUCCESS" && (
              <div className='depositmanager-success'>
                <span className='depositmanager-text04'>SUCCESS</span>
                <svg viewBox='0 0 1024 1024' className='depositmanager-icon'>
                  <path d='M864 128l-480 480-224-224-160 160 384 384 640-640z'></path>
                </svg>
                <Link to={"/"} style={{ width: "100%" }}>
                  <button className='depositmanager-button2 button'>FINISH</button>
                </Link>
              </div>
            )}

            {stage == "ERROR" && (
              <div className='depositmanager-error'>
                <span className='depositmanager-text05'>
                  <span>ERROR</span>
                  <br></br>
                  <br></br>
                  <span> TRY AGAIN</span>
                </span>
                <svg viewBox='0 0 1024 1024' className='depositmanager-icon2'>
                  <path d='M874.040 149.96c-96.706-96.702-225.28-149.96-362.040-149.96s-265.334 53.258-362.040 149.96c-96.702 96.706-149.96 225.28-149.96 362.040s53.258 265.334 149.96 362.040c96.706 96.702 225.28 149.96 362.040 149.96s265.334-53.258 362.040-149.96c96.702-96.706 149.96-225.28 149.96-362.040s-53.258-265.334-149.96-362.040zM896 512c0 82.814-26.354 159.588-71.112 222.38l-535.266-535.268c62.792-44.758 139.564-71.112 222.378-71.112 211.738 0 384 172.262 384 384zM128 512c0-82.814 26.354-159.586 71.112-222.378l535.27 535.268c-62.794 44.756-139.568 71.11-222.382 71.11-211.738 0-384-172.262-384-384z'></path>
                </svg>
                <Link to={"/"} style={{ width: "100%" }}>
                  <button className='depositmanager-button3 button'>RESTART</button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
