import React, { useState, useEffect } from "react";
import { useQuery } from "urql";

import UseritemAdd from "./useritem_add";

import UseritemSelected from "./useritem_selected";

import "./transferForm.css";

const search_profile = `
    query allProfile($filter:String) {
      allProfile(filter:{name:$filter}, fuzzy:true) {
        id
        name
        entity {
            id
            digitalId {
              id
              loginAllowed
            }
        }
        avatar {
          id
          path
        }      
      }
    }
  `;

const TransferForm = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchInputInput, setSearchInputInput] = useState("");

  const [usersearch, setUserSearch] = useState([]);
  let _usersearch = [];

  const zeros = () => {
    if (searchInput.trim().length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const szero = zeros() === true;

  const slength = () => {
    if (searchInput.trim().length < 3) {
      return true;
    } else {
      return false;
    }
  };

  const shouldPause = slength() === true;
  const [result, reexecuteQuery] = useQuery({
    query: search_profile,
    variables: { filter: searchInput.trim() },
    requestPolicy: "cache-and-network",
    pause: shouldPause,
  });
  const { data, fetching, error } = result;

  useEffect(() => {
    if (searchInput.trim().length > 2) {
      reexecuteQuery();
    }
    _usersearch = [];

    if (error) {
      _usersearch.push();
    }

    if (fetching) {
      _usersearch.push();
    }

    try {
      data.allProfile.map((emp) => {
        if (emp.entity.digitalId) {
          if (emp.entity.digitalId.loginAllowed == true) {
            _usersearch.push(
              <UseritemAdd
                rootClassName='avatarlistitemwithadder-root-class-name'
                user_name={emp.name}
                avatar={emp.avatar}
                key={emp.id}
                entity={emp.entity.id}
                data={emp}
                setData={props.setthisdata}
                setEntity={props.setSelectedEntity}></UseritemAdd>
            );
          }
        }
      });
    } catch {}

    setUserSearch(_usersearch);
  }, [searchInput]);

  const next_step = (event) => {
    event.preventDefault();
    props.setStage("SECOND");
  };

  return (
    <div className='transfer-form-container'>
      <form className='transfer-form-transfer-form' onSubmit={next_step}>
        <span className='transfer-form-text'>Search for your friend or partner and enter the amount you wish to TRANSFER to</span>
        {props.selectedEntity == "" && (
          <div className='transfer-form-container1'>
            <input
              type='text'
              placeholder='search connections'
              className='transfer-form-textinput input'
              value={searchInputInput}
              onChange={(e) => {
                setSearchInput(e.target.value.trim());
                setSearchInputInput(e.target.value);
              }}
            />
            <svg viewBox='0 0 1024 1024' className='transfer-form-icon'>
              <path d='M992.262 871.396l-242.552-206.294c-25.074-22.566-51.89-32.926-73.552-31.926 57.256-67.068 91.842-154.078 91.842-249.176 0-212.078-171.922-384-384-384-212.076 0-384 171.922-384 384s171.922 384 384 384c95.098 0 182.108-34.586 249.176-91.844-1 21.662 9.36 48.478 31.926 73.552l206.294 242.552c35.322 39.246 93.022 42.554 128.22 7.356s31.892-92.898-7.354-128.22zM384 640c-141.384 0-256-114.616-256-256s114.616-256 256-256 256 114.616 256 256-114.614 256-256 256z'></path>
            </svg>
          </div>
        )}
        {props.selectedEntity == "" && <div className='transfer-form-usercontainer'>{usersearch}</div>}
        {props.selectedEntity !== "" && (
          <div className='transfer-form-selecteduser'>
            <UseritemSelected data={props.thisdata} setrelation={props.setRelation} relation={props.relation}></UseritemSelected>
          </div>
        )}
        {props.selectedEntity !== "" && (
          <input
            type='number'
            min='1'
            step='1'
            placeholder='amount'
            required
            className='transfer-form-textinput1 input'
            value={props.amount}
            onChange={(e) => {
              props.setAmount(e.target.value);
            }}
          />
        )}
        {props.relation !== "NONE" && (
          <button type='submit' className='transfer-form-button button'>
            -&gt; NEXT -&gt;
          </button>
        )}
      </form>
    </div>
  );
};

export default TransferForm;
