import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import storageService from "./services/storage";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Provider } from "urql";
import client from "./services/api";
import "./teleporthq/style.css";
import Wallet from "./teleporthq/pages/wallet";
import Login from "./teleporthq/pages/login";
import Ledger from "./teleporthq/pages/ledger";
import PasswordChangeDID from "./teleporthq/pages/password_change_did";
import Threads from "./teleporthq/pages/threads";
import Transfer from "./teleporthq/pages/transfer";

const isAuthenticated = () => {
  const token = storageService.getStorageData("access_token");
  if (token == null) {
    return false;
  } else {
    return true;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (isAuthenticated() === true ? <Component {...props} /> : <Redirect to='/login' />)} />
);

const App = () => {
  return (
    <Provider value={client}>
      <Router>
        <PrivateRoute component={Wallet} exact path='/' />

        <Route component={Login} exact path='/login' />

        <PrivateRoute component={Ledger} exact path='/ledger/:account_no' />
        <PrivateRoute component={PasswordChangeDID} path='/password_change_did' />
        <PrivateRoute component={Threads} path='/threads' />
        <PrivateRoute component={Transfer} exact path='/transfer' />
      </Router>
    </Provider>
  );
};

const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab='home' />);

//ReactDOM.render(<App />, document.getElementById("app"));
