import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Avatar from "react-avatar";

import PropTypes from "prop-types";
import Profile from "./profile";

import "./header.css";
import storageService from "../../services/storage";

const Header = (props) => {
  const [visibility, setVisibility] = useState(false);

  const [hasmessage, setHasmessage] = useState(false);

  let main_text = "Wallet";

  const location = useLocation();
  if (location.pathname == "/") {
  } else {
    main_text = "<- " + main_text;
  }

  async function swicthProfile() {
    if (visibility == true) {
      setVisibility(false);
    } else {
      setVisibility(true);
    }
  }

  const all_profiles = JSON.parse(storageService.getStorageData("profiles"));
  const active_profile = storageService.getStorageData("active_profile");

  let profile_data = {};

  all_profiles.forEach((element) => {
    if (element["id"] === active_profile) {
      profile_data = element;
    }
    if (element["uid"] === active_profile) {
      profile_data = element;
    }
  });

  let avatar_img = "";
  try {
    if (profile_data["avatar"]["path"] == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + profile_data["avatar"]["path"];
    }
  } catch {}

  return (
    <div className={`header-header`}>
      <Link to='/' className='header-navlink'>
        {main_text}
      </Link>
      <div className='header-container'></div>
      <div className='header-container1'>
        <svg viewBox='0 0 1024 1024' className='header-icon' onClick={swicthProfile}>
          <path d='M316 366l196 196 196-196 60 60-256 256-256-256z' className=''></path>
        </svg>

        {hasmessage == false ? (
          <Link to='/threads' className='header-navlink1' style={{ display: "none" }}>
            <svg viewBox='0 0 1024 1024' className='header-nounread'>
              <path
                d='M128 337.963l359.552 251.691c14.507 10.027 33.92 10.496 48.939 0l359.509-251.691v430.037c0 11.605-4.693 22.229-12.587 30.080s-18.475 12.587-30.080 12.587h-682.667c-11.605 0-22.229-4.693-30.080-12.587s-12.587-18.475-12.587-30.080zM42.667 256.512v511.488c0 35.328 14.507 67.371 37.547 90.453s55.125 37.547 90.453 37.547h682.667c35.328 0 67.371-14.507 90.453-37.547s37.547-55.125 37.547-90.453v-511.488c0-0.427 0-0.853 0-1.28-0.213-35.029-14.635-66.773-37.547-89.685-23.083-23.040-55.125-37.547-90.453-37.547h-682.667c-35.328 0-67.371 14.507-90.453 37.547-22.912 22.912-37.333 54.656-37.547 89.728 0 0.213 0 0.469 0 0.725zM891.477 236.971l-379.477 265.6-379.477-265.6c2.048-4.096 4.779-7.808 8.021-11.051 7.893-7.893 18.517-12.587 30.123-12.587h682.667c11.605 0 22.229 4.693 30.080 12.587 3.243 3.243 5.973 6.997 8.021 11.051z'
                className=''></path>
            </svg>
          </Link>
        ) : (
          <Link to='/threads' className='header-navlink2' style={{ display: "none" }}>
            <svg viewBox='0 0 1024 1024' className='header-unread'>
              <path
                d='M128 337.963l359.552 251.691c14.507 10.027 33.92 10.496 48.939 0l359.509-251.691v430.037c0 11.605-4.693 22.229-12.587 30.080s-18.475 12.587-30.080 12.587h-682.667c-11.605 0-22.229-4.693-30.080-12.587s-12.587-18.475-12.587-30.080zM42.667 256.512v511.488c0 35.328 14.507 67.371 37.547 90.453s55.125 37.547 90.453 37.547h682.667c35.328 0 67.371-14.507 90.453-37.547s37.547-55.125 37.547-90.453v-511.488c0-0.427 0-0.853 0-1.28-0.213-35.029-14.635-66.773-37.547-89.685-23.083-23.040-55.125-37.547-90.453-37.547h-682.667c-35.328 0-67.371 14.507-90.453 37.547-22.912 22.912-37.333 54.656-37.547 89.728 0 0.213 0 0.469 0 0.725zM891.477 236.971l-379.477 265.6-379.477-265.6c2.048-4.096 4.779-7.808 8.021-11.051 7.893-7.893 18.517-12.587 30.123-12.587h682.667c11.605 0 22.229 4.693 30.080 12.587 3.243 3.243 5.973 6.997 8.021 11.051z'
                className=''></path>
            </svg>
          </Link>
        )}
        <Avatar src={avatar_img} size='34' name={profile_data["name"]} className='header-image' />
      </div>
      {visibility && <Profile setVisibility={swicthProfile} profile_data={profile_data}></Profile>}
    </div>
  );
};

Header.defaultProps = {
  rootClassName: "",
};

Header.propTypes = {
  rootClassName: PropTypes.string,
};

export default Header;
