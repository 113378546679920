import React from "react";

import MainAccountItem from "./main_account_item";
import SavingsAccountItem from "./savings_account_item";
import AidAccountItem from "./aid_account_item";
import "./personal_finance.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const PersonalFinance = (props) => {
  let _D = [];

  let all_sum = 0;

  props.data.map((item) => {
    if (item.identifier == "MAIN") {
      _D.push(<MainAccountItem data={item} key={item.accountNo} visibility={props.visibility}></MainAccountItem>);
      all_sum = all_sum + item.balance;
    } else if (item.identifier == "SAVING") {
    } else {
    }
  });

  //props.data.map((item) => {
  //  if (item.identifier == "MAIN") {
  //  } else if (item.identifier == "SAVING") {
  //    _D.push(<SavingsAccountItem data={item} key={item.accountNo} visibility={props.visibility}></SavingsAccountItem>);
  //    all_sum = all_sum + item.balance;
  //  } else {
  //  }
  //});

  props.data.map((item) => {
    if (item.identifier == "MAIN") {
    } else if (item.identifier == "SAVING") {
    } else {
      _D.push(<AidAccountItem data={item} key={item.accountNo} visibility={props.visibility}></AidAccountItem>);
      all_sum = all_sum + item.balance;
    }
  });

  return (
    <div className='personal-finance-personal-finance'>
      <div className='personal-finance-total'>
        <span className='personal-finance-text'>Total -&gt;</span>
        {props.visibility == "TRUE" ? (
          <span className='personal-finance-text1'>{numberWithCommas(all_sum) + " VT"}</span>
        ) : (
          <span
            className='personal-finance-text1'
            style={{
              color: "transparent",
              textShadow: "0 0 16px #777",
            }}>
            {numberWithCommas(all_sum) + " VT"}
          </span>
        )}
      </div>
      {_D}
    </div>
  );
};

export default PersonalFinance;
