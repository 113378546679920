import { cacheExchange, createClient, fetchExchange, dedupExchange } from "urql";
import { retryExchange } from "@urql/exchange-retry";
import { refocusExchange } from "@urql/exchange-refocus";

import storageService from "./storage";
import uuid from "react-uuid";

const app_uid = process.env.REACT_APP_APPLICATION;

const bravhur = process.env.REACT_APP_BRAVHUR_URL;
if (bravhur == null) {
} else {
  storageService.setStorageData("access_point", bravhur);
}

const dept_init = process.env.REACT_APP_DEPARTMENT;
if (dept_init == null) {
} else {
  storageService.setStorageData("active_department", dept_init);
}

const getAccessPoint = () => {
  var access_point_data = storageService.getStorageData("access_point");

  if (access_point_data == null) {
    return "https://vpldb.set.vu";
  } else {
    return access_point_data;
  }
};

const getDeviceUID = () => {
  var device_uid = storageService.getStorageData("device_uid");

  if (device_uid == null) {
    storageService.setStorageData("device_uid", uuid());
    device_uid = storageService.getStorageData("device_uid");
    return device_uid;
  } else {
    return device_uid;
  }
};

getDeviceUID();

const getProfileUID = () => {
  var profiles = storageService.getStorageData("active_profile");

  if (profiles == null) {
    return "";
  } else {
    return profiles;
  }
};

function getPayload(jwt) {
  return JSON.parse(atob(jwt.split(".")[1]));
}

const refresh_gql = `
    mutation refresh ($jti:String!){
      refresh(jti:$jti) {
            message
            accessToken
            refreshToken
            profiles {
              id
              name
              avatar {
                id
                path
              }
              entity {
                id
              }              
            }
      }
    }
  `;

const options = {
  initialDelayMs: 500,
  maxDelayMs: 1000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: (err) => err,
};

const client = createClient({
  url: getAccessPoint(),
  exchanges: [dedupExchange, refocusExchange(), cacheExchange, retryExchange(options), fetchExchange],
  fetchOptions: () => {
    let token = storageService.getStorageData("access_token");
    //let active_department = null;
    //try {
    //  active_department = storageService.getStorageData("active_department");
    //} catch {}
    const device_uid_data = getDeviceUID();
    const profile_uid = getProfileUID();
    const payload = getPayload(token);
    const jti = payload.jti;
    let expiration = new Date(payload.exp * 1000);
    const now = new Date();
    const fiveMinutes = 1000 * 60 * 2;

    if (expiration.toString() == "Invalid Date") {
      expiration = new Date(now);
    }

    if (expiration.getTime() - now.getTime() < fiveMinutes) {
      console.log("JWT refreshing...");
      const refresh_token = storageService.getStorageData("refresh_token");

      const tmp_client = createClient({
        url: storageService.getStorageData("access_point"),
        exchanges: [dedupExchange, refocusExchange(), cacheExchange, retryExchange(options), fetchExchange],

        fetchOptions: () => {
          return {
            headers: {
              device_uid: device_uid_data,
              profile_uid: profile_uid,
              nonce: uuid(),
              dep_uid: dept_init,
              app_uid: app_uid,
              authorization: refresh_token ? `Bearer ${refresh_token}` : "",
            },
          };
        },
      });

      tmp_client
        .mutation(refresh_gql, { jti: jti })
        .toPromise()
        .then((result) => {
          const { data, fetching, error } = result;
          if (error) {
            window.location.href = "/login";
          }

          if (data) {
            if (data == null) {
              window.location.href = "/login";
            } else {
              if (result["data"]["refresh"]["message"] == "OK.") {
                const new_token = result["data"]["refresh"]["accessToken"];
                storageService.setStorageData("access_token", result["data"]["refresh"]["accessToken"]);
                storageService.setStorageData("refresh_token", result["data"]["refresh"]["refreshToken"]);
                storageService.setStorageData("active_profile", result["data"]["refresh"]["profiles"][0]["id"]);
                storageService.setStorageData("profiles", JSON.stringify(result["data"]["refresh"]["profiles"]));
                console.log("Tokens refreshed...");
                if (new_token == null) {
                  return {
                    headers: { device_uid: device_uid_data, app_uid: app_uid, dep_uid: dept_init, nonce: uuid(), profile_uid: profile_uid },
                  };
                } else {
                  return {
                    headers: {
                      device_uid: device_uid_data,
                      profile_uid: profile_uid,
                      nonce: uuid(),
                      app_uid: app_uid,
                      dep_uid: dept_init,
                      authorization: new_token ? `Bearer ${new_token}` : "",
                    },
                  };
                }
              } else {
                window.location.href = "/login";
              }
            }
          }
        })
        .catch((error) => {});
    } else {
      if (token == null) {
        return {
          headers: { device_uid: device_uid_data, app_uid: app_uid, dep_uid: dept_init, nonce: uuid(), profile_uid: profile_uid },
        };
      } else {
        return {
          headers: {
            device_uid: device_uid_data,
            profile_uid: profile_uid,
            nonce: uuid(),
            dep_uid: dept_init,
            app_uid: app_uid,
            authorization: token ? `Bearer ${token}` : "",
          },
        };
      }
    }
  },
});

export default client;
