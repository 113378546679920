import React from "react";

import "./threaditem.css";

const Threaditem = (props) => {
  return (
    <div className='threaditem-threaditem'>
      <div className='threaditem-redsign'></div>
      <div className='threaditem-greysign'></div>
      <img alt='image' src='/2021_0711_00131000-01-02-01.jpeg' className='threaditem-image' />
      <div className='threaditem-container'>
        <div className='threaditem-container1'>
          <span className='threaditem-text'>Laszlo Mates</span>
          <span className='threaditem-text1'>17:28</span>
        </div>
        <span className='threaditem-text2'>blablabla... more bla bla bla</span>
      </div>
    </div>
  );
};

export default Threaditem;
