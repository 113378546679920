import React from "react";

import "./nohelpitemyet.css";

const Nohelpitemyet = (props) => {
  return (
    <div className='nohelplineyet-nohelplineyet'>
      <span className='nohelplineyet-text'>Click here to contact Vanuatu Post Customer Service</span>
      <button type='button' className='nohelplineyet-button button'>
        Contact Us
      </button>
    </div>
  );
};

export default Nohelpitemyet;
