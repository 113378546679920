import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { useQuery } from "urql";

import LedgerView from "../components/ledger_view";
import storageService from "../../services/storage";
import Header from "../components/header";
import MenuStrip from "../components/menu_strip";
import "./ledger.css";

const contractCheck = `query getMadruhamSummary($page:Int, $accountNo:String!){
    getMadruhamSummary(accountNo:$accountNo, transactionDirection:"ALL", page:$page){
      madruhamAccount {
        id
        name
        accountNo
      }  
      balance
      ts
      tsBalance
      ledgerItems
    }
    }
  `;

const Ledger = (props) => {
  let tmp = storageService.getStorageData("num_vis");
  const [visibility, setVisibility] = useState(tmp);

  if (visibility == null) {
    storageService.setStorageData("num_vis", "TRUE");
    setVisibility("TRUE");
  }

  let account_no = props.match.params.account_no;

  const [page, setPage] = useState(0);

  const [result, reexecuteQuery] = useQuery({
    query: contractCheck,
    requestPolicy: "cache-and-network",
    variables: { page: page, accountNo: account_no },
  });

  const { data, fetching, error } = result;

  let _F = [];

  if (data) {
    _F.push(
      <LedgerView
        rootClassName='ledgerview-root-class-name'
        data={data}
        set_page={setPage}
        page={page}
        visibility={visibility}
        key={1}></LedgerView>
    );
  }

  return (
    <div className='ledger-container'>
      <Helmet>
        <title>Team - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Business' />
      </Helmet>
      <Header rootClassName='header-root-class-name20'></Header>
      <div className='ledger-contentframe'>
        <div className='ledger-contentcontainer'>
          <MenuStrip rootClassName='menu-strip-root-class-name6' setVisibility={setVisibility} visibility={visibility}></MenuStrip>
          <div className='ledger-homepanel'>{_F}</div>
        </div>
      </div>
    </div>
  );
};

export default Ledger;
