import React from "react";
import { useHistory, Link } from "react-router-dom";

import "./main_account_item.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const MainAccountItem = (props) => {
  let history = useHistory();

  const openledger = () => {
    history.push("/ledger/" + props.data.accountNo);
  };

  return (
    <div className='main-account-item-main-account-item'>
      <div className='main-account-item-container'>
        <span className='main-account-item-text'>MAIN</span>

        {props.visibility == "TRUE" ? (
          <span className='main-account-item-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
        ) : (
          <span
            className='main-account-item-text1'
            style={{
              color: "transparent",
              textShadow: "0 0 16px #0099ccff",
            }}>
            {numberWithCommas(props.data.balance) + " VT"}
          </span>
        )}
      </div>
      <div className='main-account-item-container1'>
        <Link to='/transfer'>
          <svg viewBox='0 0 1024 1024' className='main-account-item-icon4'>
            <path d='M979.755 96.811c1.835-6.443 2.133-13.397 0.64-20.309-1.024-4.821-2.901-9.557-5.589-13.867-2.731-4.352-6.187-8.107-10.155-11.179-4.992-3.84-10.624-6.4-16.469-7.723s-12.032-1.451-18.176-0.171c-1.792 0.384-3.627 0.896-5.376 1.493l-0.896 0.299-852.48 298.368c-10.752 3.755-19.925 11.776-24.917 22.955-9.557 21.547 0.128 46.763 21.675 56.32l369.024 164.011 164.011 369.024c4.608 10.368 13.355 18.901 24.875 22.955 22.229 7.765 46.592-3.925 54.357-26.197l298.368-852.437c0.427-1.152 0.811-2.304 1.152-3.499zM459.904 503.765l-258.901-115.029 575.275-201.387zM836.651 247.723l-201.387 575.275-115.029-258.901z'></path>
          </svg>
        </Link>
        <div className='main-account-item-container2'></div>
        <div className='main-account-item-container3'></div>
        <svg viewBox='0 0 1024 1024' className='main-account-item-icon6' onClick={openledger}>
          <path d='M414.165 798.165l256-256c16.683-16.683 16.683-43.691 0-60.331l-256-256c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0z'></path>
        </svg>
      </div>
    </div>
  );
};

export default MainAccountItem;
