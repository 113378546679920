import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import storageService from "../../services/storage";

import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";

import { Helmet } from "react-helmet";
import { createClient, Client } from "urql";
import uuid from "react-uuid";
import "./login.css";

import QrReader from "modern-react-qr-reader";

const app_uid = process.env.REACT_APP_APPLICATION;
const dep_uid = process.env.REACT_APP_DEPARTMENT;
let accessPoint = process.env.REACT_APP_BRAVHUR_URL;

let password = process.env.REACT_APP_CY;
let salt = process.env.REACT_APP_SALT;

let iterations = 128;
let bytes = CryptoJS.PBKDF2(password, salt, { keySize: 48, iterations: iterations });
let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));

let device_uid = storageService.getStorageData("device_uid");

const PING = `
        mutation ping {
          ping {
            nonce
            initiated            
          }
        }
      `;

const LOGIN = `
        mutation loginWithID ($idNo: String!, $password: String!, $nonce: String!) {
          loginWithID(idNo:$idNo, password: $password, nonce:$nonce) {
            message
            accessToken
            refreshToken
            profiles {
              id
              name
              avatar {
                id
                path
              }
              entity {
                id
              }               
            }
          }
        }
      `;

const Login = (props) => {
  let history = useHistory();

  let header_tmp = { device_uid: device_uid, nonce: uuid(), app_uid: app_uid, dep_uid: dep_uid };

  storageService.removeStorageData("access_token");
  storageService.removeStorageData("refresh_token");
  storageService.removeStorageData("active_profile");
  storageService.removeStorageData("profiles");

  const camera = useRef(null);

  const [cameradirection, setcameradirection] = useState("environment");

  const [secondfield, setsecondfield] = useState(false);

  const [idNo, setIdNo] = useState("");

  const [login_password, setLogin_Password] = useState("");

  const resetLogin = (err) => {
    setsecondfield(false);
    setIdNo("");
  };

  const handleError = (err) => {
    //console.error(err);
  };

  const handleScan = (data) => {
    //console.error(data);
    if (data == null) {
    } else {
      if (data.startsWith("VGID")) {
        setsecondfield(true);
        setIdNo(data);
      }
    }
  };

  useEffect(() => {
    const tmp_client = createClient({
      url: accessPoint,
      fetchOptions: () => {
        return {
          headers: header_tmp,
        };
      },
    });

    tmp_client
      .mutation(PING)
      .toPromise()
      .then((result) => {
        if (result["data"]["ping"]["nonce"] == true) {
          try {
            storageService.setStorageData("pingnonce", result["data"]["ping"]["nonce"]);
          } catch {
            toast.error("Security exception");
          }
        } else {
          try {
            storageService.setStorageData("pingnonce", result["data"]["ping"]["nonce"]);
          } catch {
            toast.error("Security exception");
          }
        }
      })
      .catch((error) => {
        toast.error("Can not reach your bravhurDB instance..");
      });
  }, []);

  async function submitLogin(event) {
    event.preventDefault();

    const pn = storageService.getStorageData("pingnonce");
    const tmp_client = createClient({
      url: accessPoint,
      fetchOptions: () => {
        return {
          headers: header_tmp,
        };
      },
    });

    let ciphertext_idNo = CryptoJS.AES.encrypt(idNo.toString().split("\\")[1].split("/")[0], key, { iv: iv });
    let ciphertext_password = CryptoJS.AES.encrypt(login_password.toString(), key, { iv: iv });

    tmp_client
      .mutation(LOGIN, { idNo: ciphertext_idNo.toString(), password: ciphertext_password.toString(), nonce: pn })
      .toPromise()
      .then((result) => {
        const { data, fetching, error } = result;

        if (error) {
          if (error["message"] == "[GraphQL] @login: Nonce error") {
            window.location.reload();
          }
          toast.error(error["message"]);
        } else {
          if (data) {
            if (data["loginWithID"] != null) {
              if (result["data"]["loginWithID"]["message"] == "OK.") {
                storageService.setStorageData("access_token", result["data"]["loginWithID"]["accessToken"]);
                storageService.setStorageData("refresh_token", result["data"]["loginWithID"]["refreshToken"]);
                storageService.setStorageData("active_profile", result["data"]["loginWithID"]["profiles"][0]["id"]);
                storageService.setStorageData("profiles", JSON.stringify(result["data"]["loginWithID"]["profiles"]));
                history.push("/");
                window.location.reload();
              } else {
              }
            }
          }
        }
      })
      .catch((error) => {
        toast.error("Some errors happened..");
      });
  }

  const previewStyle = {
    facingMode: cameradirection,
  };

  return (
    <div className='login-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />

      <Helmet>
        <title>Vanuatu post - Wallet</title>
        <meta property='og:title' content='Vanuatu post - Wallet' />
      </Helmet>

      <div className='login-bodycontainer'>
        <div className='login-logincontainer'>
          {secondfield == false && (
            <QrReader
              ref={camera}
              delay={500}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
              constraints={{ facingMode: cameradirection }}
              facingMode={cameradirection}
              className='login-image'
            />
          )}

          {secondfield == false ? (
            <div className='login-container1'>
              <div className='login-container2'>
                <span className='login-text'>To login to your Vanuatu Post Wallet, first scan the QR Code of your National ID</span>
              </div>
            </div>
          ) : (
            <form className='login-loginform' onSubmit={submitLogin}>
              <h1 className='login-logintext'>Password for your National ID</h1>
              <input
                type='password'
                id='password'
                name='password'
                required
                minLength='8'
                placeholder='password'
                autoComplete='current-password'
                className='login-loginpasswordinput input'
                pattern="^[a-zA-Z0-9!#$%&'()*+,-.\/:;<=>?@\[\]\^_{|}~]{6,}$"
                value={login_password}
                onChange={(e) => {
                  setLogin_Password(e.target.value);
                }}
              />
              <div className='login-container3'>
                <span className='login-text1'>By logging in you agree to our terms and conditions.</span>
              </div>
              <button type='submit' className='login-loginbutton button'>
                LOGIN
              </button>
            </form>
          )}
          <span className='login-text2' onClick={resetLogin}>
            RESTART
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
