import React from "react";
import { useHistory } from "react-router-dom";

import "./aid_account_item.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const AidAccountItem = (props) => {
  let history = useHistory();

  const openledger = () => {
    history.push("/ledger/" + props.data.accountNo);
  };

  return (
    <div className='aid-account-item-aid-account-item'>
      <div className='aid-account-item-container'>
        <span className='aid-account-item-text'>{props.data.accountName}</span>
        {props.visibility == "TRUE" ? (
          <span className='aid-account-item-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
        ) : (
          <span
            className='aid-account-item-text1'
            style={{
              color: "transparent",
              textShadow: "0 0 16px #0099ccff",
            }}>
            {numberWithCommas(props.data.balance) + " VT"}
          </span>
        )}
      </div>
      <div className='aid-account-item-container1'>
        <div className='aid-account-item-container2'></div>
        <svg viewBox='0 0 1024 1024' className='aid-account-item-icon' onClick={openledger}>
          <path d='M414.165 798.165l256-256c16.683-16.683 16.683-43.691 0-60.331l-256-256c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0z'></path>
        </svg>
      </div>
    </div>
  );
};

export default AidAccountItem;
