import React from "react";

import "./ledgeritempositive.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Ledgeritempositive = (props) => {
  let _d = new Date(props.data.timestamp * 1000);
  return (
    <div className='ledgeritempositive-ledgeritempositive'>
      <span className='ledgeritempositive-typetext'>{props.data.transaction_type}</span>
      <span className='ledgeritempositive-nametext'>{props.data.participant}</span>
      {props.visibility == "TRUE" ? (
        <span className='ledgeritempositive-amounttext'>{numberWithCommas(props.data.amount) + " VT"}</span>
      ) : (
        <span
          className='ledgeritempositive-amounttext'
          style={{
            color: "transparent",
            textShadow: "0 0 16px #008C72",
          }}>
          {numberWithCommas(props.data.amount) + " VT"}
        </span>
      )}
      <div className='ledgeritempositive-bottomcont'>
        <span className='ledgeritempositive-bytext'>{props.data.transacted_by}</span>
        <span className='ledgeritempositive-datetext'>{_d.toISOString().replace("T", " ")}</span>
      </div>
    </div>
  );
};

Ledgeritempositive.defaultProps = {};

Ledgeritempositive.propTypes = {};

export default Ledgeritempositive;
