import React, { useState } from "react";

import { Helmet } from "react-helmet";
import storageService from "../../services/storage";
import Header from "../components/header";
import ChangePasswordDID from "../components/change_password_did";
import "./password_change.css";
import MenuStrip from "../components/menu_strip";

const PasswordChangeDID = (props) => {
  let tmp = storageService.getStorageData("num_vis");
  const [visibility, setVisibility] = useState(tmp);

  if (visibility == null) {
    storageService.setStorageData("num_vis", "TRUE");
    setVisibility("TRUE");
  }

  return (
    <div className='passwordchange-container'>
      <Helmet>
        <title>password change - bravhurDB</title>
        <meta
          name='description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
        <meta property='og:title' content='password change - bravhurDB' />
        <meta
          property='og:description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
      </Helmet>
      <Header rootClassName='header-root-class-name12'></Header>
      <div className='passwordchange-contentframe'>
        <div className='passwordchange-contentcontainer'>
          <MenuStrip rootClassName='menu-strip-root-class-name6' setVisibility={setVisibility} visibility={visibility}></MenuStrip>

          <div className='passwordchange-homepanel'>
            <div className='passwordchange-leftpanel'>
              <span className='passwordchange-leftpaneltext'>Change Password</span>
              <ChangePasswordDID rootClassName='changepassword-root-class-name'></ChangePasswordDID>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeDID;
